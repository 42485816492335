import { PRE_PAYMENT, POST_PAYMENT, SET_PAYMENT_USED } from './action-types';
import { CREATE_BOOKING, LOAD_BOOKING } from '../booking/action-types';
import { SUCCESS } from '../../constants';

const initialState = {
  paypal: {},
  postPayment: undefined,
  paymntRecId: undefined,
  paymntUsed: undefined,
  cardinal: {},
  payit: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BOOKING:
    case LOAD_BOOKING:
      return {
        ...initialState,
      };
    case PRE_PAYMENT:
    case POST_PAYMENT:
      return {
        ...state,
        postPayment: undefined,
      };
    case PRE_PAYMENT + SUCCESS:
      return {
        ...state,
        postPayment: undefined,
        paymntRecId:
          action.payload.response.data.data.paymntRecId || action.payload.response.data.data.paypal.paymntRecId, // Slight hack as paymntRecId moved
        ...action.payload.response.data.data,
      };
    case SET_PAYMENT_USED:
      return {
        ...state,
        paymntUsed: action.payload,
      };
    case POST_PAYMENT + SUCCESS:
      return {
        ...state,
        postPayment: action.payload.response.data.data || {},
      };
    default:
      return state;
  }
};

export default reducer;
