import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import t from '../../../text';
import OfferBoxes from '../../components/HomePage/OfferBoxes';
import ScrollToTop from '../../components/ScrollToTop';

import ColumnComponent from '../../components/Common/ColumnComponent';
import Banner from '../../components/HomePage/Banner';
import Countdown from '../../components/HomePage/Countdown';
import Hero from '../../components/HomePage/Hero';

function Home({ content }) {
  const components = content.map(({ type, data }, index) => {
    switch (type) {
      case 'HeroText':
        return (
          <Hero
            key={index}
            heading={data.heading}
            headingImage={data.heading_image}
            subheading={data.subheading}
            desktopBackground={data.hero_image}
            mobileBackground={data.mobile_hero_image}
          />
        );
      case 'Banner':
        return (
          <Banner key={index} fluid={data.fluid} bg={data.bg} text={data.text} content={data.content} url={data.url} />
        );
      case 'Countdown':
        return (
          <Countdown
            key={index}
            status={data.status}
            datetime={data.datetime}
            title={data.title}
            text={data.text}
            linktext={data.linktext}
            url={data.url}
            background_colour={data.background_colour}
            text_colour={data.text_colour}
          />
        );
      case 'HomeColumns':
        return (
          <Fragment key={index}>
            <ColumnComponent
              heading={data.heading}
              columns={data.columns}
              background={data.background_colour || 'white'}
            />
            {data.heading.includes('Breakfree Deals') && <OfferBoxes />}
          </Fragment>
        );
      default:
        return <div key={index} />;
    }
  });

  return (
    <>
      <Helmet>
        <title key="title">{t('home.page.title')}</title>
      </Helmet>
      <ScrollToTop />
      {components}
    </>
  );
}

export default Home;
