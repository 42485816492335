import { GET_CHANNELS } from './action-types';
import { getRequestStatus } from '../../requests/selectors';
import { sortBasedOnProp } from '../../../utils/utils';

export const CHANNEL_ORDER = [
  'UK Holiday Parks',
  'European Holiday Parks',
  'UK Lodge Breaks',
  'UK Cottages',
  'UK Camping & Touring Parks',
  'Boating Holidays',
];

export const channelsSelector = (state) => state.data.channels.channels;

export const channelsSortedSelector = (state) => {
  const channelData = state.data.channels.channels;
  return sortBasedOnProp(channelData, CHANNEL_ORDER, 'name');
};
export const validChannelSelector = (state) => {
  const channelData = state.data.channels.channels;
  const selectedChannel = state.search.form.channelId;
  const canFindChannel = channelData.find((channel) => channel.code === selectedChannel);
  if (!canFindChannel) return false;
  return canFindChannel.isChannel;
};

export const [isFetchingSelector, hasErrorSelector, errorDataSelector] = getRequestStatus(GET_CHANNELS);
