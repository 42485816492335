import { config } from '../../../config';
import { arrayFromXtoY } from '../../utils/utils';
import { HOLIDAY_TYPES } from '../../Constants';

export const getNightsOptions = (channelId) => {
  switch (channelId) {
    case HOLIDAY_TYPES.UK_HOLIDAY_PARKS:
      if (config['searchBox.allowFiveDays']) return [2, 3, 4, 5, 7, 10, 11, 14];
      else return [2, 3, 4, 7, 10, 11, 14];
    case 'uktouring':
      return arrayFromXtoY(1, 21);
    case 'boats':
      return [3, 4, 5, 6, 7, 8, 9, 10, 11, 14];
    case 'eurocaravan':
      return [3, 4, 7, 14];
    case 'ukcottages':
      return [...arrayFromXtoY(1, 14), 21];
    default:
      return [2, 3, 4, 7, 10, 11, 14];
  }
};

export const getUnavailableDaysDefault = (duration = 0) => {
  switch (duration) {
    case 2:
      return [0, 1, 2, 3, 4, 5]; // Allow Saturdays only
    case 3:
    case 10:
      return [0, 1, 2, 3, 4, 6]; // Allow Fridays only
    case 4:
    case 11:
      return [0, 2, 3, 4, 5, 6]; // Allow Mondays only
    case 5:
      return [0, 1, 3, 5, 6]; // Allow Tuesday and Thursday
    case 7:
    case 14:
      return [0, 2, 3, 4]; // Allow Mondays, Fridays, Saturdays
    default:
      return [0, 1, 2, 3, 4, 5, 6]; // Otherwise disable all days
  }
};

export const parseMonth = (month) => {
  const m = parseInt(month) + 1;
  return m.toString().padStart(2, '0');
};
